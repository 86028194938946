// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import jardent from "assets/images/logos/gray-logos/logo.png";

// Constants
import CONSTANTS from "constants.js/constants";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "¿Aún no nos sigues en redes sociales?",
    image: jardent,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: CONSTANTS.URL_FACEBOOK,
    },
    {
      icon: <InstagramIcon />,
      link: CONSTANTS.URL_INSTAGRAM,
    },
  ],
  menus: [
    {
      name: "Formas de pago",
      items: [
        { name: "Tarjetas de débito y crédito", href: "#" },
        { name: "Transferencia SPEI", href: "#" },
        { name: "Efectivo", href: "#" },
      ],
    },
    {
      name: "Visítanos",
      items: [{ name: "Consultorio Dental Jardent", href: CONSTANTS.URL_MAPS_LOCATION }],
    },
    {
      name: "Otros",
      items: [{ name: "Leer el aviso de privacidad", route: "/privacy-policy" }],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}.
    </MKTypography>
  ),
};
