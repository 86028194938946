// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
// import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
// import Pages from "pages/Presentation/sections/Pages";
import InstagramFeed from "pages/Presentation/sections/Instagram";
// import Download from "pages/Presentation/sections/Download";

// Presentation page components
// import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.png";
import jardent from "assets/images/logos/gray-logos/logo.png";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://wa.me/message/HMUR6VENV2VXL1",
          label: "Agendar cita 🦷",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {" "}
              <MKBox position="relative">
                <MKBox component="img" src={jardent} alt="jardent" width="85%" sx={{ mt: 40 }} />
              </MKBox>
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* <Counters /> */}
        {/* <Information /> */}
        <DesignBlocks />
        {/* <Pages /> */}
        {/* <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container> */}
        <InstagramFeed />
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon=""
                title="En JARDENT "
                description="Nuestro compromiso es proporcionar tratamientos dentales completos y de alta calidad, abordando las necesidades de cada paciente con profesionalismo y atención personalizada."
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon=""
                title="¡No hay nada mejor que sonreír!"
                description="Sabemos que una sonrisa lo cambia todo. Nos dedicamos con profesionalismo a cuidar tu salud bucal, comprometiéndonos plenamente con cada paciente. Nuestro objetivo es que te sientas seguro al sonreír, por eso nos enfocamos en la prevención y te brindamos las recomendaciones necesarias para evitar posibles molestias o complicaciones en el futuro."
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon=""
                title="Salud dental"
                description="¡Sonrisas saludables, felicidad garantizada! En Salud Dental, ofrecemos consultas dentales excepcionales y tratamientos especializados de ortodoncia y endodoncia. Cada tratamiento está cuidadosamente diseñado para prevenir, diagnosticar y tratar problemas dentales, garantizando que restauremos tu salud bucal y te devolvamos la sonrisa que tanto deseas."
              />
            </Grid>
          </Grid>
        </Container>
        {/* <Download /> */}
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Agenda tu cita YA!!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  🦷🦷🦷
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              />
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
