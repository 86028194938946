/**
  All of the routes for the Material Kit 2 React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
// import Icon from "@mui/material/Icon";

// @mui icons
// import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import ContactPageIcon from "@mui/icons-material/ContactPage";

// Pages
// TODO
// import ContactUsLayout from "layouts/pages/landing-pages/ContactUs"; TODO
// import SignInPageLayout from "layouts/pages/authentication/sign-in";
// import AuthorLayout from "layouts/pages/landing-pages/Author";
import AboutUsLayout from "layouts/pages/landing-pages/AboutUs";
import FeedbackLayout from "layouts/pages/landing-pages/Feedback";
import PrivacyPolicyLayout from "layouts/pages/landing-pages/PrivacyPolicy";

// Constants
import CONSTANTS from "constants.js/constants";

const routes = [
  {
    name: "Acerca de nosotros",
    route: "/about-us",
    icon: <ContactPageIcon />,
    component: <AboutUsLayout />,
  },
  {
    name: "Facebook",
    icon: <FacebookIcon />,
    href: CONSTANTS.URL_FACEBOOK,
  },
  {
    name: "Instagram",
    icon: <InstagramIcon />,
    href: CONSTANTS.URL_INSTAGRAM,
  },
  {
    route: "/privacy-policy",
    component: <PrivacyPolicyLayout />,
  },
  // {
  //   route: "/feedback",
  //   component: <FeedbackLayout />,
  // },
];

export default routes;
