import React from "react";

export default function SimpleMap() {

  return (
    <iframe
      title="Google Maps Embed"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d747.0131666960826!2d-99.07071269517539!3d19.33836559460567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce0212a87146f7%3A0x26f7f615cfb573cb!2sConsultorio%20Dental%20Jardent!5e0!3m2!1ses-419!2smx!4v1713925135948!5m2!1ses-419!2smx"
      width="100%"
      height="450"
      style={{ border: "0" }}
      allowfullscreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  );
}
