const CONSTANTS = {
  URL_INSTAGRAM: "https://www.instagram.com/cdjardent",
  URL_FACEBOOK: "https://www.facebook.com/cdjardent",
  URL_WHATSAPP: "https://wa.me/message/HMUR6VENV2VXL1",
  URL_MAPS_LOCATION: "https://maps.google.com?q=19.338609,-99.071226",
  PRIVACY_POLICY: "",
  SERVICE_PATH_FEEDBACK: "/php/feedback.php",
};

export default CONSTANTS;
