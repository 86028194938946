

// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Team from "pages/LandingPages/AboutUs/sections/Team";
import Us from "pages/LandingPages/AboutUs/sections/Us";
import SimpleMap from "pages/LandingPages/AboutUs/sections/Maps";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Icons
import WhastAppIcon from "@mui/icons-material/WhatsApp";

// Images
import bgImage from "assets/images/main/jardent_presentacion.gif";

// Constants
import CONSTANTS from "constants.js/constants";

function AboutUs() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: CONSTANTS.URL_WHATSAPP,
          label: "Agendar cita 🦷",
          color: "default",
          name: "WhatsApp",
          icon: <WhastAppIcon />,
        }}
        transparent
        light
      />
      <MKBox
        minHeight="60vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Us />
        <SimpleMap />
        <Team />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
