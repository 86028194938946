

import ortoF from "assets/images/treatments/orto_f.png";
import ortoB from "assets/images/treatments/orto_b.jpg";

import limpF from "assets/images/treatments/limp_f.png";

import cariesF from "assets/images/treatments/caries_f.jpg";
import cariesB from "assets/images/treatments/caries_b.jpg";

import oclusalesF from "assets/images/treatments/oclusales_f.jpg";

import cxF from "assets/images/treatments/cx_f.jpg";
import cxyextf from "assets/images/treatments/ext_f.png";

import endoF from "assets/images/treatments/endodoncia_f.jpg";

import protesisF from "assets/images/treatments/protesis_f.jpg";

import odontoF from "assets/images/treatments/odonto_f.jpg";
import odontoB from "assets/images/treatments/odonto_b.png";

import blancF from "assets/images/treatments/b_f.jpg";

import estF from "assets/images/treatments/esteticadental_f.jpg";
import estB from "assets/images/treatments/esteticadental_b2.jpg";

export default [
  {
    title: "Nuestros tratamientos",
    description: "",
    items: [
      {
        imageFront: ortoF,
        imageBack: ortoB,
        name: "Ortodoncia",
        route: "#",
      },
      {
        imageFront: limpF,
        imageBack: limpF,
        name: "Limpieza dental",
        route: "#",
      },
      {
        imageFront: cariesF,
        imageBack: cariesB,
        name: "Eliminación de caries",
        route: "#",
      },
      {
        imageFront: oclusalesF,
        imageBack: oclusalesF,
        name: "Guardas oclusales",
        route: "#",
      },
      {
        imageFront: cxF,
        imageBack: cxyextf,
        name: "Cirugía de terceros molares ó muelas del juicio",
        route: "#",
      },
      {
        imageFront: cxyextf,
        imageBack: cxyextf,
        name: "Extracciones",
        route: "#",
      },
      {
        imageFront: endoF,
        imageBack: endoF,
        name: "Endodoncia",
        route: "#",
      },
      {
        imageFront: protesisF,
        imageBack: protesisF,
        name: "Prótesis",
        route: "#",
      },
      {
        imageFront: odontoF,
        imageBack: odontoB,
        name: "Odontopediatría",
        route: "#",
      },
      {
        imageFront: blancF,
        imageBack: blancF,
        name: "Blanqueamiento dental",
        route: "#",
      },
      {
        imageFront: estF,
        imageBack: estB,
        name: "Estética dental",
        route: "#",
      },
    ],
  },
  // {
  //   title: "Tratamientos dentales",
  //   description: "",
  //   items: [
  //     {
  //       image: ortoImg,
  //       name: "Ortodoncia",
  //       route: "#",
  //     },
  //     {
  //       image: blancImg,
  //       name: "Blanqueamiento dental",
  //       route: "#",
  //     },
  //     {
  //       image: implImg,
  //       name: "Implantes dentales",
  //       route: "#",
  //     },
  //   ],
  // },
];
