

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/privacy/privacy.png";

function PrivacyPolicy() {
  return (
    <>
      <MKBox position="absolute" top="0.5rem" width="100%">
        <DefaultNavbar routes={routes} />
      </MKBox>
      <Grid container spacing={3} alignItems="fixed">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Aviso de Privacidad
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body" color="text" mb={3}>
                Consultorio Dental Jardent mejor conocido como jardent, con domicilio en Primera
                cerrada de Domingo Ramirez, Paraje 9, San Juan, 09830 Ciudad de México, CDMX y
                portal de internet https://jardent.com.mx, es el responsable del uso y protección de
                sus datos personales, y al respecto le informamos lo siguiente: ¿Para qué fines
                utilizaremos sus datos personales? Los datos personales que recabamos de usted, los
                utilizaremos para las siguientes finalidades que son necesarias para el servicio que
                Respuesta a mensajes del formulario de contacto, Prestación de cualquier servicio
                solicitado ¿Dónde puedo consultar el aviso de privacidad integral? conocer mayor
                información sobre los términos y condiciones en que serán tratados sus datos
                personales, como los terceros con quienes compartimos su información personal y la
                forma en que podrá ejercer sus derechos ARCO, puede consultar el aviso de privacidad
                integral con una petición vía correo electrónico: contacto@jardent.com.mx
              </MKTypography>
              <MKTypography variant="body2" color="text" mb={2}>
                Última actualización de este aviso de privacidad: 02/02/2023
              </MKTypography>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default PrivacyPolicy;
