/* eslint-disable react/jsx-no-duplicate-props */
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// @mui material components
import Icon from "@mui/material/Icon";

// Images
import jardent from "assets/images/logos/gray-logos/logo.png";

function Us() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">Contáctanos</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
            ¡Estamos aquí para ayudarte a sonreír con confianza! Si tienes alguna pregunta sobre nuestros servicios dentales o deseas programar una cita, no dudes en comunicarte con nosotros. Nuestro equipo amable y profesional está listo para atenderte y brindarte la mejor atención odontológica. ¡Esperamos verte pronto y ayudarte a mantener tu sonrisa brillante y saludable!
            </MKTypography>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <MKButton
                  variant="gradient"
                  color="info"
                  href="tel:5510061465"
                  sx={{ height: "100%" }}
                >
                  Llamar&nbsp;
                  <Icon>phone</Icon>
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={jardent} alt="jardent" width="85%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Us;
