/* eslint-disable react/no-array-index-key */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";
// eslint-disable-next-line import/no-extraneous-dependencies
import { InstagramEmbed } from "react-social-media-embed";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";

// // Material Kit 2 React examples
// import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// // Images
// import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
// import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
// import nasaLogo from "assets/images/logos/gray-logos/logo-nasa.svg";
// import vodafoneLogo from "assets/images/logos/gray-logos/logo-vodafone.svg";
// import digitalOceanLogo from "assets/images/logos/gray-logos/logo-digitalocean.svg";
import Instagram from "@mui/icons-material/Instagram";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

function InstagramFeed() {
  // Array de objetos con la información de cada publicación
  const instagramPosts = [
    {
      url: "https://www.instagram.com/p/C3VwPfPOXmQ/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      width: 328,
      captioned: false,
    },
    {
      url: "https://www.instagram.com/reel/C2DI5GHOCFR/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      width: 328,
      captioned: false,
    },
    {
      url: "https://www.instagram.com/p/C1-zLjTut9o/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
      width: 328,
      captioned: false,
    },
    {
      url: "https://www.instagram.com/p/CvBK0GxOShg/?utm_source=ig_web_button_share_sheet&igsh=MzRlODBiNWFlZA==",
      width: 328,
      captioned: false,
    },
  ];

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
          <MKBadge variant="contained" color="" badgeContent="Instagram" container sx={{ mb: 2 }} />
          <MKTypography variant="h2" fontWeight="bold">
            Echa un Vistazo a Nuestro Trabajo y Conéctate con Nosotros en Instagram
          </MKTypography>
          <MKTypography variant="h2" fontWeight="bold">
            :)
          </MKTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <InstagramEmbed
                  url={instagramPosts[0].url}
                  width={instagramPosts[0].width}
                  captioned={instagramPosts[0].captioned}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <InstagramEmbed
                  url={instagramPosts[1].url}
                  width={instagramPosts[1].width}
                  captioned={instagramPosts[1].captioned}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
            <Grid item xs={12} md={6}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <InstagramEmbed
                  url={instagramPosts[2].url}
                  width={instagramPosts[2].width}
                  captioned={instagramPosts[2].captioned}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <InstagramEmbed
                  url={instagramPosts[3].url}
                  width={instagramPosts[3].width}
                  captioned={instagramPosts[3].captioned}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default InstagramFeed;
